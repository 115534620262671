.input {
    color: #fff;
    margin-bottom: .5em;
    text-align: center;
}

::-webkit-calendar-picker-indicator {
    filter: invert(100%);
}

.chakra-radio__label {
    color: #fff;
}

.m-10 {
    margin-top: 10px;
}

.accountAlready {
    color: #919191;
}

.link {
    /* underline */
    text-decoration: underline;
}
