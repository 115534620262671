.app {
    background-color: #070707;
    height: 100vh;
}

.text {
    color: #ebebeb;
}

.button-glow {
    border-radius: 20px;
    border: 3px solid #5941a9;
    padding: 10px;
    margin-top: 2em;
    color: #5941a9;
    font-size: 1.2em;
    transition: all .5s ease;
}

.button-glow:hover {
    color: #9671ff;
    box-shadow: 0 0 20px 5px #5941a9;
    -webkit-box-shadow: 0 0 20px 5px #5941a9;
}

.text-center {
    text-align: center;
}

.error {
    color: #bf0603;
    margin-top: 5px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.chakra-select {
    background-color: #070707;
    color: #ebebeb;
    border-color: #5941a9;
}

.chakra-select option {
    background-color: #070707;
    color: #ebebeb;
}
