html,body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

/* use BlenderPro-Heavy.woff2 */
@font-face {
    font-family: 'BlenderPro';
    src: url('fonts/BlenderPro-Heavy.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

/* use BlenderPro-Medium.woff2 */

h1 {
    font-family: 'BlenderPro';
    font-weight: 900;
    font-size: 3em;
    color: #000;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
}
